<template>
  <div id="userinfo">
    <el-descriptions class="margin-top" title="个人资料" :column="3" border>
      <!-- <template slot="extra">
        <el-button type="primary" size="small" @click="onEdit">修改资料</el-button>
      </template> -->
      <!-- 用户名 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user-solid"></i>
          用户名
        </template>
        {{ userInfo.userName || "--" }}
      </el-descriptions-item>
      <!-- 手机号 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          手机号
        </template>
        {{ userInfo.mobile || "--" }}
      </el-descriptions-item>
      <!-- 注册日期 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-date"></i>
          注册日期
        </template>
        {{ userInfo.createTime || "--" }}
      </el-descriptions-item>
      <!-- 最近登录 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-date"></i>
          最近登录
        </template>
        {{ userInfo.updateTime || "--" }}
      </el-descriptions-item>
      <!-- 头像 -->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-picture-outline"></i>
          头像
        </template>
        <el-image class="user-avatar" :src="
          (userInfo.userAvatarUrl && userInfo.userAvatarUrl.filePath) ||
          circleUrl
        " :preview-src-list="
  userInfo.userAvatarUrl && [userInfo.userAvatarUrl.filePath]
">
        </el-image>
        <UpLoad @fileIds="getFileIds" :fileType="1"></UpLoad>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { getMyselfInfo } from "@/api/setting/user";

import UpLoad from "@/components/upload";
export default {
  components: { UpLoad },
  data() {
    return {
      userInfo: {},
      token: { Authorization: localStorage.getItem("playOne-store-token") },
      circleUrl: require("../../assets/images/layout/head.png"), //默认头像
    };
  },
  mounted() {
    this.getMyselfInfo();
  },
  methods: {
    // 【请求】个人信息
    getMyselfInfo() {
      getMyselfInfo().then((res) => {
        this.userInfo = res.data;
      });
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      console.log('fileIds: ', fileIds);
    },

    // 【监听】修改个人资料
    onEdit() {
      this.$message('暂无修改权限')
    },
  },
};
</script>


<style lang="scss" scoped>
#userinfo {
  padding: .15rem;
  box-sizing: border-box;
}

.user-avatar {
  width: 0.48rem;
  height: 0.48rem;
  cursor: pointer;
  border-radius: 50%;
}
</style>
